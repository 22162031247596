// import React from "react";
// import styles from "./MainBlog.module.css";
// import slide1 from "./slide1.png";

// const MainBlog = () => {
//   const blog = {
//     image: slide1,
//     author: "John Doe",
//     header: "Innovative Strategies for Modern Logistics",
//     datePublished: "July 27, 2024",
//   };

//   return (
//     <div className={styles.mainBlog}>
//       <div className={styles.blogRow}>
//         <div className={styles.blogImage}>
//           <img src={blog.image} alt="Blog" />
//         </div>
//         <div className={styles.blogDetails}>
//           <h3 className={styles.author}>{blog.author}</h3>
//           <h2 className={styles.blogHeader}>{blog.header}</h2>
//           <div className={styles.blogMeta}>
//             <span>{blog.datePublished}</span>
//             <span> • </span>
//             <span>5 min read</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MainBlog;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MainBlog.module.css";
import client, { urlFor } from "../sanityClient";

const MainBlog = () => {
  const [blog, setBlog] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const query = `*[_type == "blogPost"][0]`;
        const data = await client.fetch(query);
        setBlog(data);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlog();
  }, []);

  if (!blog) {
    return <p></p>;
  }

  const handleReadMore = (blog) => {
    navigate(`/blog/${blog._id}`, { state: { blog } });
  };

  return (
    <div className={styles.mainBlog} onClick={() => handleReadMore(blog)}>
      <div className={styles.blogRow}>
        <div className={styles.blogImage}>
          <img src={urlFor(blog.image).url()} alt="Blog" />
        </div>
        <div className={styles.blogDetails}>
          <h3 className={styles.author}>{blog.author}</h3>
          <h2 className={styles.blogHeader}>{blog.header}</h2>
          <div className={styles.blogMeta}>
            <span>{new Date(blog.date).toLocaleDateString()}</span>
            <span> • </span>
            <span>{blog.readTime} min read</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBlog;
