// import React, { useState } from "react";
// import styles from "./Faq.module.css";

// const faqData = [
//   {
//     question: "What is your return policy?",
//     answer:
//       "Digital marketing allows businesses to reach and engage with a wider audience, generate leads, drive website traffic, and increase brand visibility. It provides measurable results, allows for targeted marketing efforts, and enables businesses to adapt and optimize their strategies based on data and insights.",
//   },
//   {
//     question: "How do I track my order?",
//     answer: "You can track your order by ...",
//   },
//   {
//     question: "Do you offer technical support?",
//     answer: "Yes, we offer technical support ...",
//   },
//   {
//     question: "What payment methods do you accept?",
//     answer: "We accept Visa, MasterCard ...",
//   },
// ];

// const Faq = () => {
//   const [activeIndex, setActiveIndex] = useState(0);

//   const handleToggle = (index) => {
//     setActiveIndex(index === activeIndex ? null : index);
//   };

//   return (
//     <div className={styles.faqContainer}>
//       <div className={styles.leftColumn}>
//         <h2 className={styles.faqHeader}>Frequently Asked Questions</h2>
//         <p className={styles.faqPara}>
//           As a leading digital marketing agency, we are dedicated to providing
//           comprehensive educational resources and answering frequently asked
//           questions to help our clients.
//         </p>
//         <div className={styles.contactBtnDiv}>
//           <button className={styles.contactButton}>Contact Us</button>
//         </div>
//       </div>
//       <div className={styles.rightColumn}>
//         {faqData.map((item, index) => (
//           <div key={index} className={styles.faqItem}>
//             <div
//               className={styles.faqQuestion}
//               onClick={() => handleToggle(index)}
//             >
//               {item.question}
//               <span className={styles.toggleSign}>
//                 {activeIndex === index ? "-" : "+"}
//               </span>
//             </div>
//             {activeIndex === index && (
//               <div className={styles.faqAnswer}>{item.answer}</div>
//             )}
//             <hr />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Faq;
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate hook
import client from "../../sanityClient"; // Adjust the import according to your file structure
import styles from "./Faq.module.css";

const Faq = () => {
  const [content, setContent] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = '*[_type == "content"]';
        const result = await client.fetch(query);

        if (result.length > 0) {
          setContent(result[0]); // Assuming only one content document
        }
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  const handleToggle = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const handleScroll = (event, sectionId) => {
    event.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className={styles.faqContainer}>
      <div className={styles.leftColumn}>
        {content ? (
          <>
            <h2 className={styles.faqHeader}>{content.faqHeader}</h2>
            <p className={styles.faqPara}>{content.faqPara}</p>
            <div className={styles.contactBtnDiv}>
              <Link
                to="#contact-us"
                className={styles.contactButton}
                onClick={(e) => handleScroll(e, "contact-us")}
              >
                {content.contactButtonText || "Contact Us"}
              </Link>
            </div>
          </>
        ) : (
          <p></p>
        )}
      </div>
      <div className={styles.rightColumn}>
        {content &&
          content.faqs.map((item, index) => (
            <div key={index} className={styles.faqItem}>
              <div
                className={styles.faqQuestion}
                onClick={() => handleToggle(index)}
              >
                {item.question}
                <span className={styles.toggleSign}>
                  {activeIndex === index ? "-" : "+"}
                </span>
              </div>
              {activeIndex === index && (
                <div className={styles.faqAnswer}>{item.answer}</div>
              )}
              <hr />
            </div>
          ))}
      </div>
      {/* <div className={styles.badgeContainer}>
        {content && (
          <>
            <div className={styles.badgeText}>{content.badgeText}</div>
            <p className={styles.badgeDescription}>
              {content.badgeDescription}
            </p>
          </>
        )}
      </div> */}
    </div>
  );
};

export default Faq;
