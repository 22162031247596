// import React, { useState } from "react";
// import styles from "./Map.module.css";
// import asiaImage from "./map1.png";
// import africaImage from "./map1.png";
// import europeImage from "./map1.png";
// // ... import other continent images

// const continents = {
//   Mediterranean: [
//     "Barcelona",
//     "Genova",
//     "Istanbul",
//     "Piraeus",
//     "Le Havre",
//     "Izmir",
//   ],
//   Gulf: ["Jebel Ali", "Sohar", "Hamad", "Dammam", "Shuwaikh"],
//   Africa: ["Durban", "Mombasa", "Port Louis"],
//   Europe: [],
//   USA: [],
//   SouthAmercia: [],
//   Canada: [],
//   FarEast: [],
//   China: [],
//   SWP: [],
// };

// const continentImages = {
//   Mediterranean: asiaImage,
//   Gulf: africaImage,
//   Africa: europeImage,
//   Europe: europeImage,
//   USA: europeImage,
//   SouthAmercia: europeImage,
//   Canada: europeImage,
//   FarEast: europeImage,
//   China: europeImage,
//   SWP: europeImage,
// };

// const Maps = () => {
//   const [activeContinent, setActiveContinent] = useState("Mediterranean");

//   return (
//     <div className={styles.container}>
//       <div className={styles.badge}>OUR GLOBAL REACH</div>
//       <h1 className={styles.header}>Discover Your Nearest Port!</h1>
//       <div className={styles.tabs}>
//         {Object.keys(continents).map((continent) => (
//           <button
//             key={continent}
//             className={`${styles.tabButton} ${
//               activeContinent === continent ? styles.active : ""
//             }`}
//             onClick={() => setActiveContinent(continent)}
//           >
//             {continent}
//           </button>
//         ))}
//       </div>
//       <div className={styles.content}>
//         <div className={styles.countries}>
//           <ul>
//             {continents[activeContinent].map((country) => (
//               <li key={country}>{country}</li>
//             ))}
//           </ul>
//         </div>
//         <div className={styles.imageContainer}>
//           <img src={continentImages[activeContinent]} alt={activeContinent} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Maps;
import React, { useState, useEffect } from "react";
import client, { urlFor } from "../../sanityClient";
import styles from "./Map.module.css";

const Maps = () => {
  const [globalReach, setGlobalReach] = useState({});
  const [activeContinent, setActiveContinent] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    client
      .fetch('*[_type == "globalReach"][0]')
      .then((data) => {
        setGlobalReach(data);
        if (data.continents.length > 0) {
          setActiveContinent(data.continents[0].name);
        }
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Set loading to false even if there is an error
      });
  }, []);

  if (loading) {
    return (
      <div>{/* <Circles color="#ffffff" height={80} width={80} /> */}</div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.badge}>{globalReach.badge}</div>
      <h1 className={styles.header}>{globalReach.header}</h1>
      <div className={styles.tabs}>
        {globalReach?.continents &&
          globalReach?.continents.map((continent) => (
            <button
              key={continent?.name}
              className={`${styles.tabButton} ${
                activeContinent === continent?.name ? styles.active : ""
              }`}
              onClick={() => setActiveContinent(continent.name)}
            >
              {continent.name}
            </button>
          ))}
      </div>
      <div className={styles.content}>
        {globalReach?.continents &&
          globalReach?.continents
            .filter((continent) => continent?.name === activeContinent)
            .map((continent) => (
              <React.Fragment key={continent?.name}>
                <div className={styles.countries}>
                  <ul>
                    {continent?.ports?.map((port, index) => (
                      <li key={index}>{port}</li>
                    ))}
                  </ul>
                </div>
                <div className={styles.imageContainer}>
                  <img
                    src={urlFor(continent?.image)?.url()}
                    alt={continent?.name}
                  />
                </div>
              </React.Fragment>
            ))}
      </div>
    </div>
  );
};

export default Maps;
