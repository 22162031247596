import React from "react";
import CookiesPolicy from "./CookiesPolicy";
import Footer from "../../content/footer/Footer";

const CookiesPage = () => {
  return (
    <div>
      <CookiesPolicy />
      <Footer />
    </div>
  );
};

export default CookiesPage;
