import styles from "./Services.module.css";
import client from "../../sanityClient"; // Adjust the import path as needed
import { useEffect, useState } from "react";

const Services = () => {
  const [servicesData, setServicesData] = useState([]);
  const [badgeText, setBadgeText] = useState("");
  const [viewMoreText, setViewMoreText] = useState("");
  const [viewLessText, setViewLessText] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [fadeClass, setFadeClass] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from Sanity
    const fetchData = async () => {
      try {
        const query = `{
          "services": *[_type == "service"]{
            _id,
            icon {
              asset -> {
                url
              }
            },
            name,
            description
          },
          "section": *[_type == "serviceSection"][0]{
            badgeText,
            viewMoreText,
            viewLessText
          }
        }`;
        const data = await client.fetch(query);
        setServicesData(data.services);
        setBadgeText(data.section.badgeText);
        setViewMoreText(data.section.viewMoreText);
        setViewLessText(data.section.viewLessText);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  const toggleServices = () => {
    setFadeClass(showMore ? "hide" : "showMore");
    setTimeout(() => {
      setShowMore(!showMore);
      setFadeClass("");
    }, 300); // Match duration with CSS animation
  };

  const displayedServices = showMore ? servicesData : servicesData.slice(0, 6);

  if (loading) {
    return <div></div>; // You can replace this with a loading spinner or skeleton
  }

  return (
    <div className="text-center md:mt-[4vw] bg-[#fcfcfc]">
      <div className="inline-flex my-4 px-6 py-3 justify-center items-center gap-2 rounded-full border border-[#a1a1a1] text-black font-poppins text-[1.1rem] font-normal leading-tight">
        {badgeText}
      </div>
      <div
        className={`flex flex-wrap justify-between opacity-100 md:px-[10vw]  transition-opacity duration-300 w-full mx-auto mt-[4vw] bg-[#fcfcfc] ${fadeClass}`}
      >
        {displayedServices.map((service) => (
          <div
            key={service._id}
            className="w-full md:w-[40%] lg:w-[30%] md:mt-8 flex flex-col md:items-start items-center p-4 md:p-0"
          >
            <img
              src={service.icon.asset.url}
              alt={service.name}
              className="h-[48px] w-[48px] mb-[22px]"
            />
            <div className="text-[#1c1f35] font-rubik text-[25.01px] font-normal md:text-start text-center">
              {service.name}
            </div>
            <div className="text-[#808080] text-[16px] font-medium leading-[1.52] mt-[13px] md:text-start text-center">
              {service.description}
            </div>
          </div>
        ))}
      </div>
      <button
        className=" w-fit px-4 py-2 mt-8 justify-center items-center gap-8 rounded-full border border-[#0a47c9] text-[#0a47c9] font-poppins text-[0.9rem] font-medium leading-[1.4] tracking-tight bg-white transition-colors duration-300 mb-[80px]"
        onClick={toggleServices}
      >
        {showMore ? viewLessText : viewMoreText}
      </button>
    </div>
  );
};

export default Services;
