// import React, { useState, useEffect } from "react";
// import styles from "./Work.module.css";
// import workIcon from "./workImg.png";
// import workSlide from "./workSlider.png";
// import workSlide2 from "./workSlider2.png";

// const Work = () => {
//   const images = [workSlide, workSlide2, workSlide]; // Replace with actual image paths
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 3000); // Change image every 3 seconds
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className={styles.workContainer}>
//       <div className={styles.workDiv}>
//         <div className={styles.badge}>HOW WE WORK</div>
//         <h1 className={styles.header}>
//           Simplify Your Shipping Experience with Our Easy Step Process
//         </h1>
//         <div className={styles.row}>
//           <div className={styles.imageContainer}>
//             <img
//               src={images[currentImageIndex]}
//               alt="Work"
//               className={styles.image}
//             />
//           </div>
//           <div className={styles.infoContainer}>
//             <div className={styles.infoRow}>
//               <img src={workIcon} className={styles.icon} alt="work" />
//               <div>
//                 <h2 className={styles.sideHead}>Customs Paperwork</h2>
//                 <p className={styles.sideDesc}>
//                   First, we meticulously handle customs paperwork, ensuring
//                   compliance and swift processing for seamless logistics
//                   operations.
//                 </p>
//               </div>
//             </div>
//             <div className={styles.infoRow}>
//               <img src={workIcon} className={styles.icon} alt="work" />
//               <div>
//                 <h2 className={styles.sideHead}>Transportation</h2>
//                 <p className={styles.sideDesc}>
//                   First, we meticulously handle customs paperwork, ensuring
//                   compliance and swift processing for seamless logistics
//                   operations.
//                 </p>
//               </div>
//             </div>
//             <div className={styles.infoRow}>
//               <img src={workIcon} className={styles.icon} alt="work" />
//               <div>
//                 <h2 className={styles.sideHead}>Forwarding</h2>
//                 <p className={styles.sideDesc}>
//                   First, we meticulously handle customs paperwork, ensuring
//                   compliance and swift processing for seamless logistics
//                   operations.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Work;

import imageUrlBuilder from "@sanity/image-url";
import client from "../../sanityClient";
import { useEffect, useState } from "react";
import styles from "./Work.module.css";

const builder = imageUrlBuilder(client);

const urlFor = (source) => builder.image(source);

const Work = () => {
  const [data, setData] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const query = '*[_type == "workContent"]';
      const result = await client.fetch(query);
      setData(result[0]); // Assuming you only have one document
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data && data.images && data.images.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % data.images.length
        );
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [data]);

  if (!data) {
    return <div></div>;
  }

  const images = data.images.map((img) => urlFor(img).url());

  return (
    <div id="work" className="text-center mt-8 py-8 px-2 lg:mt-20 bg-[#f6f9ff]">
    <div className="">
      <div className="inline-flex px-[2vw] py-[1vw] rounded-full border border-[#a1a1a1] text-[#000] text-sm lg:text-[1.25vw] font-normal bg-[#f6f9ff]">
        {data.badge}
      </div>
      <h1 className="text-[#000] text-xl lg:text-[2.25vw] lg:leading-[4.2vw] font-semibold mt- mb-0">
        {data.header}
      </h1>
      <div className="flex flex-col items-start justify-start mt-6 lg:mt-12 lg:flex-row md:px-[8vw]">
        <div className="flex justify-center items-center  flex-1">
          <img
            src={images[currentImageIndex]}
            alt="Work"
            className="w-[95%] lg:max-w-[36vw] transition-opacity duration-500 mt-6"
          />
        </div>
        <div className="flex flex-col justify-start flex-1 my-6">
          {data.infoRows.map((info, index) => (
            <div className="flex lg:items-start lg:flex-row flex-col items-center justify-center md:justify-start  mb-8" key={index}>
              <img
                src={urlFor(info.icon).url()}
                className="w-20 md:w-[6vw] mr-4"
                alt="work"
              />
              <div className="flex flex-col lg:items-start items-center">
                <h2 className="text-[#1c1f35] text-xl 2xl:text-[1.4vw] 2xl:leading-[2.2vw] font-medium">
                  {info.title}
                </h2>
                <p className="text-[#666c89] text-center lg:text-start text-base 2xl:text-[1.2vw] 2xl:leading-[2vw] font-medium mt-2">
                  {info.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  );
};

export default Work;
