import React, { useState, useEffect } from "react";
import styles from "./Dimension.module.css";
import client from "../../sanityClient"; // Adjust the path as needed

const Dimension = () => {
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState("20 FEET DRY CONTAINER");
  const [header, setHeader] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the dimension data from Sanity
    client
      .fetch(
        '*[_type == "dimension"]{title, image{asset->{url}}, details, backgroundImage{asset->{url}}}'
      )
      .then((result) => {
        const dimensionData = result.reduce((acc, item) => {
          acc[item.title] = {
            image: item.image.asset.url,
            details: item.details,
            backgroundImage: item.backgroundImage?.asset?.url || "",
          };
          return acc;
        }, {});
        setData(dimensionData);

        // Set the first tab as default and header text
        if (Object.keys(dimensionData).length > 0) {
          setActiveTab(Object.keys(dimensionData)[0]);
          setBackgroundImage(
            dimensionData[Object.keys(dimensionData)[0]].backgroundImage
          );
        }
        // Fetch header text
        client
          .fetch('*[_type == "header"]{headerText}')
          .then((headerResult) => {
            if (headerResult.length > 0) {
              setHeader(headerResult[0].headerText);
            }
            setLoading(false); // Set loading to false once data is fetched
          })
          .catch((error) => {
            console.error(error);
            setLoading(false); // Set loading to false in case of error
          });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setBackgroundImage(data[tab].backgroundImage);
  };

  if (loading) {
    return <div className={styles.loading}></div>; // You can replace this with a spinner or any loading indicator
  }

  return (
    <div
      className={styles.dimensionContainer}
      id="fleet"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={styles.wrapperBorder}>
        <div className={styles.row}>
          <div className={styles.imageCol}>
            {data[activeTab] && (
              <img
                src={data[activeTab].image}
                alt={activeTab}
                className={styles.image}
              />
            )}
          </div>
          <div className={styles.dimensionCol}>
            {data[activeTab] && (
              <table className={styles.dimensionTable}>
                <tbody>
                  <tr>
                    <td>Width</td>
                    <td>{data[activeTab].details.width}</td>
                  </tr>
                  <tr>
                    <td>Height</td>
                    <td>{data[activeTab].details.height}</td>
                  </tr>
                  <tr>
                    <td>Load Capacity</td>
                    <td>{data[activeTab].details.loadCapacity}</td>
                  </tr>
                  <tr>
                    <td>Weight</td>
                    <td>{data[activeTab].details.weight}</td>
                  </tr>
                  <tr>
                    <td>Max Load</td>
                    <td>{data[activeTab].details.maxLoad}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className={styles.headerDiv}>
          <h2 className={styles.header}>
            {header ||
              "Discover our advanced range of containers for reliable logistics solutions"}
          </h2>
        </div>

        <div className={styles.tabContainer}>
          {Object.keys(data).map((tab) => (
            <button
              key={tab}
              className={`${styles.tabButton} ${
                activeTab === tab ? styles.activeTab : ""
              }`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dimension;
