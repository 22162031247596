// import React from "react";
// import styles from "./Transportation.module.css";
// import img from "./image2.png";

// const Transportation = () => {
//   return (
//     <div className={styles.container}>
//       <div className={styles.blankCol}></div>
//       <div className={styles.contentCol}>
//         <div className={styles.badge}>Mode of Transportation</div>
//         <div className={styles.headerDiv}>
//           <h2 className={styles.header}>
//             We will select the optimal <br />
//             transport for any request
//           </h2>
//           <p className={styles.description}>
//             Whether by land, sea, or air, we ensure reliable and efficient
//             delivery of your cargo. With expertise in navigating global trade
//             routes and optimizing transit times, we provide seamless integration
//             of transportation modes.
//           </p>
//         </div>

//         <div className={styles.imageContainer}>
//           {Array.from({ length: 3 }).map((_, index) => (
//             <div key={index} className={styles.imageWrapper}>
//               <img src={img} alt="Transportation" className={styles.image} />
//               <div className={styles.hoverText}>
//                 Transportation Option {index + 1}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Transportation;
import React, { useState, useEffect } from "react";
import client from "../../sanityClient";
import styles from "./Transportation.module.css";
import leftIcon from "./leftIcon.png";

const Transportation = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "transportation"]{
          badge,
          header,
          description,
          images[]{
            image{asset->{url}},
            hoverText,
            hoverHead
          }
        }`
      )
      .then((data) => setContent(data[0]))
      .catch(console.error);
  }, []);

  if (!content) return <div></div>;

  return (
    <div className={styles.container}>
      <div className={styles.blankCol}>
        <div className={styles.iconTextWrapper}>
          <img src={leftIcon} />
          <span className={styles.iconText}>
            Pioneer Cargo Solutions- transport logistics with quality mark
          </span>
        </div>
      </div>
      <div className={styles.contentCol}>
        <div className={styles.badge}>{content.badge}</div>
        <div className={styles.headerDiv}>
          <h2 className={styles.header}>
            {content.header.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </h2>
          <p className={styles.description}>{content.description}</p>
        </div>

        <div className={styles.imageContainer}>
          {content.images.map((item, index) => (
            <div key={index} className={styles.imageWrapper}>
              <img
                src={item.image.asset.url}
                alt="Transportation"
                className={styles.image}
              />
              <div className={styles.overlay}>
                <div className={styles.hoverHead}>{item.hoverHead}</div>
                <div className={styles.hoverText}>{item.hoverText}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Transportation;
