// import React from "react";
// import styles from "./PrivacyPolicy.module.css";

// const PrivacyPolicy = () => {
//   return (
//     <div className={styles.privacyPolicy}>
//       <h1 className={styles.topText}>At Abravic, we respect your privacy</h1>
//       <h2 className={styles.heading}>Privacy Policy</h2>
//       <p className={styles.text}>
//         Lorem ipsum dolor sit amet consectetur. Felis nunc consequat massa
//         egestas feugiat quis tristique. Fringilla venenatis sagittis in
//         pellentesque lorem fermentum tincidunt velit tempor. Ac laoreet non
//         accumsan tincidunt eleifend placerat adipiscing vulputate. Fames urna
//         elementum molestie vel nam sit etiam congue pellentesque interdum turpis
//         non magna molestie.
//       </p>
//       <div className={styles.date}>
//         <p className={styles.effectiveDate}>Effective date: 04/2024</p>
//         <p className={styles.subheading}>Privacy policy of Abravic</p>
//       </div>

//       <div className={styles.section}>
//         <h3 className={styles.sectionHeading}>General Provisions</h3>
//         <p className={styles.text}>
//           Lorem ipsum dolor sit amet consectetur. Felis nunc consequat massa
//           egestas feugiat quis tristique. Fringilla venenatis sagittis in
//           pellentesque lorem fermentum tincidunt velit tempor. Ac laoreet non
//           accumsan tincidunt eleifend placerat adipiscing vulputate. Fames urna
//           elementum molestie vel nam sit etiam congue pellentesque interdum
//           turpis non magna molestie.
//         </p>
//         <p className={styles.text}>
//           Lorem ipsum dolor sit amet consectetur. Felis nunc consequat massa
//           egestas feugiat quis tristique. Fringilla venenatis sagittis in
//           pellentesque lorem fermentum tincidunt velit tempor. Ac laoreet non
//           accumsan tincidunt eleifend placerat adipiscing vulputate. Fames urna
//           elementum molestie vel nam sit etiam congue pellentesque interdum
//           turpis non magna molestie.
//         </p>
//         <p className={styles.text}>
//           Lorem ipsum dolor sit amet consectetur. Felis nunc consequat massa
//           egestas feugiat quis tristique. Fringilla venenatis sagittis in
//           pellentesque lorem fermentum tincidunt velit tempor. Ac laoreet non
//           accumsan tincidunt eleifend placerat adipiscing vulputate. Fames urna
//           elementum molestie vel nam sit etiam congue pellentesque interdum
//           turpis non magna molestie.
//         </p>
//       </div>
//       <div className={styles.section}>
//         <h3 className={styles.sectionHeading}>Other Provisions</h3>
//         <p className={styles.text}>
//           Lorem ipsum dolor sit amet consectetur. Felis nunc consequat massa
//           egestas feugiat quis tristique. Fringilla venenatis sagittis in
//           pellentesque lorem fermentum tincidunt velit tempor. Ac laoreet non
//           accumsan tincidunt eleifend placerat adipiscing vulputate. Fames urna
//           elementum molestie vel nam sit etiam congue pellentesque interdum
//           turpis non magna molestie.
//         </p>
//         <p className={styles.text}>
//           Lorem ipsum dolor sit amet consectetur. Felis nunc consequat massa
//           egestas feugiat quis tristique. Fringilla venenatis sagittis in
//           pellentesque lorem fermentum tincidunt velit tempor. Ac laoreet non
//           accumsan tincidunt eleifend placerat adipiscing vulputate. Fames urna
//           elementum molestie vel nam sit etiam congue pellentesque interdum
//           turpis non magna molestie.
//         </p>
//         <p className={styles.text}>
//           Lorem ipsum dolor sit amet consectetur. Felis nunc consequat massa
//           egestas feugiat quis tristique. Fringilla venenatis sagittis in
//           pellentesque lorem fermentum tincidunt velit tempor. Ac laoreet non
//           accumsan tincidunt eleifend placerat adipiscing vulputate. Fames urna
//           elementum molestie vel nam sit etiam congue pellentesque interdum
//           turpis non magna molestie.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default PrivacyPolicy;

import React, { useEffect, useState } from "react";
import styles from "./PrivacyPolicy.module.css";
import client from "../../sanityClient";

const PrivacyPolicy = () => {
  const [policy, setPolicy] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "privacyPolicy"]{
          topText,
          heading,
          text,
          effectiveDate,
          subheading,
          sections[] {
            sectionHeading,
            sectionText
          }
        }`
      )
      .then((data) => {
        setPolicy(data[0]);
      })
      .catch(console.error);
  }, []);

  if (!policy) {
    return <div></div>;
  }

  return (
    <div className={styles.privacyPolicy}>
      <h1 className={styles.topText}>{policy.topText}</h1>
      <h2 className={styles.heading}>{policy.heading}</h2>
      <div className={styles.text}>
        {policy.text.map((block, index) => (
          <p key={index}>{block.children[0].text}</p>
        ))}
      </div>
      <div className={styles.date}>
        <p className={styles.effectiveDate}>{policy.effectiveDate}</p>
        <p className={styles.subheading}>{policy.subheading}</p>
      </div>

      {policy.sections.map((section, index) => (
        <div className={styles.section} key={index}>
          <h3 className={styles.sectionHeading}>{section.sectionHeading}</h3>
          {section.sectionText.map((block, idx) => (
            <p className={styles.text} key={idx}>
              {block.children[0].text}
            </p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PrivacyPolicy;
