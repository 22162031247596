import React, { useEffect, useState } from "react";
import styles from "./MacroServiceHome.module.css";
import client, { urlFor } from "../../sanityClient"; // Adjust path as necessary

const MacroServiceHome = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `
          *[_type == "macroServiceHome"][0] {
            badge,
            description,
            images[] {
              image,
              heading,
              text
            },
            rightDescription
          }
        `;
        const result = await client.fetch(query);
        console.log("result", result);
        setData(result);
      } catch (err) {
        setError("Failed to fetch data");
        console.error("Failed to fetch data", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewMore = () => {
    setShowMore(true);
  };

  const handleViewLess = () => {
    setShowMore(false);
  };

  if (loading) return <div></div>;
  if (error) return <div className={styles.error}>{error}</div>;
  if (!data) return <div className={styles.noData}>No Data Available</div>;

  const initialImages = data.images.slice(0, 3);
  const additionalImages = data.images;

  return (
    <main className="macro_services flex flex-col items-center lg:items-start p-[4vw]">
      <div className="w-full flex flex-col items-center lg:items-start lg:mb-8 mb-4 ">
        <p className="w-fit px-8 py-2 rounded-full border border-blue-700 text-lg text-blue-700">
          {data.badge}
        </p>
        <h1 className="text-[#000] lg:text-start text-center w-full lg:w-1/2 text-2xl md:text-[1.65vw] md:leading-[2.4vw]">
          {data.description}
        </h1>
      </div>

      {!showMore ? (
        <div className="w-full  flex flex-col items-center justify-center lg:items-start lg:justify-start  lg:flex-row lg:gap-[1vw]">
          <div className="w-full lg:w-3/5 flex flex-wrap gap-[1vw]">
            {/* Display the initial set of images */}
            {initialImages.map((item, index) => (
              <div key={index} className="relative w-[32%] mb-2">
                <img
                  src={urlFor(item.image)}
                  alt={item.heading}
                  className="w-full rounded-sm"
                />
                <div className="absolute bottom-0 bg-black p-2 lg:pb-4 w-full opacity-85 text-[#fff] flex flex-col items-center lg:items-start">
                  <h3 className="text-white opacity-100 text-[8px] lg:text-[calc(1vw+2px)]  lg:text-start text-center ">
                    {item.heading}
                  </h3>
                  <p className="text-white opacity-80 lg:text-start text-center text-[7px] md:text-[8px] lg:text-xs mt-2">
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="lg:w-2/5 w-full flex flex-col lg:items-start items-center">
            <p className="text-lg font-normal lg:text-start text-center">{data.rightDescription}</p>
            <button
              className="border-blue-600 border  rounded-full px-4 py-2 text-base text-[#000] mt-4"
              onClick={handleViewMore}
            >
              View More
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-[1vw]">
            {/* Display additional images in a grid */}
            {additionalImages.map((item, index) => (
              <div key={index} className="relative mb-2">
                <img
                  src={urlFor(item.image)}
                  alt={item.heading}
                  className="w-full rounded-sm"
                />
                <div className="absolute bottom-0 bg-black p-2 lg:pb-2 w-full opacity-85 text-[#fff]">
                  <h3 className="text-white opacity-100 text-[8px] lg:text-[calc(1vw+2px)]">
                    {item.heading}
                  </h3>
                  <p className="text-white opacity-80 text-[6px] leading-3 lg:text-xs mt-2">
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="w-full flex justify-center mt-4">
            <button
              className="border-blue-600 border rounded-full px-4 py-2 text-base text-[#000]"
              onClick={handleViewLess}
            >
              View Less
            </button>
          </div>
        </div>
      )}
    </main>
  );
};

export default MacroServiceHome;
