// import React from "react";
// import { Link } from "react-router-dom";
// import styles from "./Footer.module.css";
// import fbIcon from "./fb.jpg";
// import linkedin from "./linkedin.jpg";
// import xIcon from "./x.jpg";
// import igIcon from "./ig.jpg";
// import footerLogo from "./footerLogo.png";
// import btnLogo from "./btnLogo.png";

// const Footer = () => {
//   return (
//     <footer className={styles.footer}>
//       <div className={styles.heroSection}>
//         <h1 className={styles.heroText}>Get Your Cargo Moving!</h1>
//         <button className={styles.heroButton}>Get Started</button>
//       </div>
//       <div className={styles.mainContent}>
//         <div className={styles.leftColumn}>
//           <div className={styles.logo}>
//             <img src={footerLogo} className={styles.logoImg} alt="flogo" />
//           </div>
//           <div className={styles.socialIcons}>
//             <div className={styles.iconContainer}>
//               <img src={fbIcon} className={styles.iconS} alt="social" />
//             </div>
//             <div className={styles.iconContainer}>
//               <img src={linkedin} className={styles.iconS} alt="social" />
//             </div>
//             <div className={styles.iconContainer}>
//               <img src={xIcon} className={styles.iconS} alt="social" />
//             </div>
//             <div className={styles.iconContainer}>
//               <img src={igIcon} className={styles.iconS} alt="social" />
//             </div>
//           </div>
//         </div>
//         <div className={styles.rightColumn}>
//           <h2 className={styles.header}>
//             One-stop solution for all your logistics need!
//           </h2>
//           <button className={styles.connectButton}>
//             <img src={btnLogo} className={styles.btnLogo} alt="btnLogo" />
//             made by Indic Lancers
//           </button>
//         </div>
//       </div>
//       <div className={styles.dottedLines}></div>
//       <div className={styles.bottomContent}>
//         <div className={styles.policies}>
//           <Link to="/shipping-policy">Shipping Policy</Link>
//           <Link to="/privacy-policy">Privacy Policy</Link>
//           <Link to="/cookie-policy">Cookie Policy</Link>
//         </div>
//         <div className={styles.copyright}>
//           Copyright © 2024 ABRAVIC SHIPPING
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Footer.module.css";
import client from "../../sanityClient"; // Import your Sanity client

const Footer = () => {
  const [footerData, setFooterData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.fetch(`
          *[_type == "footer"][0]{
            heroText,
            heroButtonText,
            logo {
              asset-> {
                url
              }
            },
            logoLink,
            socialIcons[] {
              icon {
                asset-> {
                  url
                }
              },
              url
            },
            headerText,
            buttonText,
            buttonLogo {
              asset-> {
                url
              }
            },
            buttonLink
          }
        `);
        setFooterData(data);
      } catch (err) {
        console.error("Failed to fetch footer data:", err);
      }
    };

    fetchData();
  }, []);

  if (!footerData) return <div></div>;
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleScroll = (event, sectionId) => {
    event.preventDefault();
    handleClose();
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.heroSection}>
        <h1 className={styles.heroText}>{footerData.heroText}</h1>
        {/* <Link to="#contact-us" onClick={(e) => handleScroll(e, "contact-us")}>
          <p className={styles.navItemSubText}> Contact Us</p>
        </Link> */}
        <Link
          className={styles.heroButton}
          to="#contact-us"
          onClick={(e) => handleScroll(e, "contact-us")}
        >
          {footerData.heroButtonText} &rarr;
        </Link>
      </div>
      <div className={styles.mainContent}>
        <div className={styles.leftColumn}>
          <div className={styles.logo}>
            <Link
            to='/'
            >
              <img
                src={footerData?.logo?.asset?.url || "/default-logo.png"}
                className={styles.logoImg}
                alt="footer logo"
              />
            </Link>
          </div>
          <div className={styles.socialIcons}>
            {footerData.socialIcons?.map((icon, index) => (
              <div key={index} className={styles.iconContainer}>
                <a href={icon.url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={icon.icon.asset.url || "/default-icon.png"}
                    className={styles.iconS}
                    alt="social"
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.rightColumn}>
          <h2 className={styles.header}>{footerData.headerText}</h2>
          <a
            href={footerData?.buttonLink || "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className={styles.connectButton}>
              <img
                src={
                  footerData?.buttonLogo?.asset?.url || "/default-btn-logo.png"
                }
                className={styles.btnLogo}
                alt="button logo"
              />
              {footerData.buttonText}
            </button>
          </a>
        </div>
      </div>
      <div className={styles.dottedLines}></div>
      <div className={styles.bottomContent}>
        <div className={styles.policies}>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/cookie-policy">Cookie Policy</Link>
        </div>
        <div className={styles.copyright}>
          Copyright © 2024 ABRAVIC SHIPPING
        </div>
      </div>
    </footer>
  );
};

export default Footer;
