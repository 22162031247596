import React from "react";
import ServiceIntroText from "./ServicIntroText/ServiceIntroText";
import ServiceHero from "./serviceHero/ServiceHero";
import MacroService from "./macroService/MacroService";
import Blogs from "./blogs/Blogs";
import ServiceInfo from "./serviceInfo/ServiceInfo";
import Form from "../content/form/Form";
import Faq from "./faq/Faq";
import Footer from "../content/footer/Footer";

const ServicePage = () => {
  return (
    <div>
      <ServiceIntroText />
      <ServiceHero />
      <ServiceInfo />
      <MacroService />
      <Blogs />
      <Faq />
      <Form />
      <Footer />
    </div>
  );
};

export default ServicePage;
