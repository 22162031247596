// import React, { useState } from "react";
// import styles from "./LatestPost.module.css";
// import blog1 from "./blog1.png";
// import blog2 from "./blog2.png";

// // Dummy data for blogs
// const blogData = [
//   {
//     id: 1,
//     category: "Tech",
//     image: blog1,
//     author: "John Doe",
//     header: "Tech Blog 1",
//     date: "2024-07-25",
//     readTime: "5 min read",
//     content: [
//       {
//         subTitle: "Introduction to Supply Chain Optimization",
//         paragraph:
//           "An introduction to the basics of supply chain optimization, explaining its importance and the general strategies used.",
//       },
//       {
//         subTitle: "Key Strategies for Efficiency",
//         paragraph:
//           "This section delves into specific strategies to enhance supply chain efficiency, including lean management, just-in-time inventory, and technology integration.",
//       },
//       {
//         subTitle: "Challenges and Solutions",
//         paragraph:
//           "Discussion of common challenges in supply chain management and practical solutions to overcome these obstacles, ensuring a smooth and efficient process.",
//       },
//     ],
//   },
//   {
//     id: 2,
//     category: "Design",
//     image: blog2,
//     author: "Jane Smith",
//     header: "Design Blog 1",
//     date: "2024-07-24",
//     readTime: "4 min read",
//     content: [
//       {
//         subTitle: "Introduction to Supply Chain Optimization",
//         paragraph:
//           "An introduction to the basics of supply chain optimization, explaining its importance and the general strategies used.",
//       },
//       {
//         subTitle: "Key Strategies for Efficiency",
//         paragraph:
//           "This section delves into specific strategies to enhance supply chain efficiency, including lean management, just-in-time inventory, and technology integration.",
//       },
//       {
//         subTitle: "Challenges and Solutions",
//         paragraph:
//           "Discussion of common challenges in supply chain management and practical solutions to overcome these obstacles, ensuring a smooth and efficient process.",
//       },
//     ],
//   },
//   {
//     id: 3,
//     category: "How to",
//     image: blog2,
//     author: "Alice Johnson",
//     header: "How to Blog 1",
//     date: "2024-07-23",
//     readTime: "6 min read",
//     content: [
//       {
//         subTitle: "Introduction to Supply Chain Optimization",
//         paragraph:
//           "An introduction to the basics of supply chain optimization, explaining its importance and the general strategies used.",
//       },
//       {
//         subTitle: "Key Strategies for Efficiency",
//         paragraph:
//           "This section delves into specific strategies to enhance supply chain efficiency, including lean management, just-in-time inventory, and technology integration.",
//       },
//       {
//         subTitle: "Challenges and Solutions",
//         paragraph:
//           "Discussion of common challenges in supply chain management and practical solutions to overcome these obstacles, ensuring a smooth and efficient process.",
//       },
//     ],
//   },
//   {
//     id: 4,
//     category: "Tech",
//     image: blog1,
//     author: "Bob Brown",
//     header: "Tech Blog 2",
//     date: "2024-07-22",
//     readTime: "5 min read",
//     content: [
//       {
//         subTitle: "Introduction to Supply Chain Optimization",
//         paragraph:
//           "An introduction to the basics of supply chain optimization, explaining its importance and the general strategies used.",
//       },
//       {
//         subTitle: "Key Strategies for Efficiency",
//         paragraph:
//           "This section delves into specific strategies to enhance supply chain efficiency, including lean management, just-in-time inventory, and technology integration.",
//       },
//       {
//         subTitle: "Challenges and Solutions",
//         paragraph:
//           "Discussion of common challenges in supply chain management and practical solutions to overcome these obstacles, ensuring a smooth and efficient process.",
//       },
//     ],
//   },
//   {
//     id: 5,
//     category: "Design",
//     image: blog2,
//     author: "Charlie Davis",
//     header: "Design Blog 2",
//     date: "2024-07-21",
//     readTime: "4 min read",
//     content: [
//       {
//         subTitle: "Introduction to Supply Chain Optimization",
//         paragraph:
//           "An introduction to the basics of supply chain optimization, explaining its importance and the general strategies used.",
//       },
//       {
//         subTitle: "Key Strategies for Efficiency",
//         paragraph:
//           "This section delves into specific strategies to enhance supply chain efficiency, including lean management, just-in-time inventory, and technology integration.",
//       },
//       {
//         subTitle: "Challenges and Solutions",
//         paragraph:
//           "Discussion of common challenges in supply chain management and practical solutions to overcome these obstacles, ensuring a smooth and efficient process.",
//       },
//     ],
//   },
//   {
//     id: 6,
//     category: "How to",
//     image: blog1,
//     author: "David Lee",
//     header: "How to Blog 2",
//     date: "2024-07-20",
//     readTime: "6 min read",
//     content: [
//       {
//         subTitle: "Introduction to Supply Chain Optimization",
//         paragraph:
//           "An introduction to the basics of supply chain optimization, explaining its importance and the general strategies used.",
//       },
//       {
//         subTitle: "Key Strategies for Efficiency",
//         paragraph:
//           "This section delves into specific strategies to enhance supply chain efficiency, including lean management, just-in-time inventory, and technology integration.",
//       },
//       {
//         subTitle: "Challenges and Solutions",
//         paragraph:
//           "Discussion of common challenges in supply chain management and practical solutions to overcome these obstacles, ensuring a smooth and efficient process.",
//       },
//     ],
//   },
//   {
//     id: 7,
//     category: "Tech",
//     image: blog2,
//     author: "Eve White",
//     header: "Tech Blog 3",
//     date: "2024-07-19",
//     readTime: "5 min read",
//     content: [
//       {
//         subTitle: "Introduction to Supply Chain Optimization",
//         paragraph:
//           "An introduction to the basics of supply chain optimization, explaining its importance and the general strategies used.",
//       },
//       {
//         subTitle: "Key Strategies for Efficiency",
//         paragraph:
//           "This section delves into specific strategies to enhance supply chain efficiency, including lean management, just-in-time inventory, and technology integration.",
//       },
//       {
//         subTitle: "Challenges and Solutions",
//         paragraph:
//           "Discussion of common challenges in supply chain management and practical solutions to overcome these obstacles, ensuring a smooth and efficient process.",
//       },
//     ],
//   },
//   {
//     id: 8,
//     category: "Press",
//     image: blog1,
//     author: "Frank Moore",
//     header: "Press Blog 1",
//     date: "2024-07-18",
//     readTime: "5 min read",
//     content: [
//       {
//         subTitle: "Introduction to Supply Chain Optimization",
//         paragraph:
//           "An introduction to the basics of supply chain optimization, explaining its importance and the general strategies used.",
//       },
//       {
//         subTitle: "Key Strategies for Efficiency",
//         paragraph:
//           "This section delves into specific strategies to enhance supply chain efficiency, including lean management, just-in-time inventory, and technology integration.",
//       },
//       {
//         subTitle: "Challenges and Solutions",
//         paragraph:
//           "Discussion of common challenges in supply chain management and practical solutions to overcome these obstacles, ensuring a smooth and efficient process.",
//       },
//     ],
//   },
//   {
//     id: 9,
//     category: "Blogs",
//     image: blog2,
//     author: "Grace Lee",
//     header: "Blogs Blog 1",
//     date: "2024-07-17",
//     readTime: "5 min read",
//     content: [
//       {
//         subTitle: "Introduction to Supply Chain Optimization",
//         paragraph:
//           "An introduction to the basics of supply chain optimization, explaining its importance and the general strategies used.",
//       },
//       {
//         subTitle: "Key Strategies for Efficiency",
//         paragraph:
//           "This section delves into specific strategies to enhance supply chain efficiency, including lean management, just-in-time inventory, and technology integration.",
//       },
//       {
//         subTitle: "Challenges and Solutions",
//         paragraph:
//           "Discussion of common challenges in supply chain management and practical solutions to overcome these obstacles, ensuring a smooth and efficient process.",
//       },
//     ],
//   },
//   // Add more dummy blogs as needed
// ];

// const LatestPost = () => {
//   const [activeTab, setActiveTab] = useState("All");
//   const [visibleBlogs, setVisibleBlogs] = useState(6);

//   const handleTabClick = (category) => {
//     setActiveTab(category);
//     setVisibleBlogs(6); // Reset to show limited blogs when changing category
//   };

//   const handleViewMore = () => {
//     setVisibleBlogs(blogData.length);
//   };

//   const handleViewLess = () => {
//     setVisibleBlogs(6);
//   };

//   const filteredBlogs =
//     activeTab === "All"
//       ? blogData
//       : blogData.filter((blog) => blog.category === activeTab);

//   return (
//     <div className={styles.container}>
//       <div className={styles.wrapper}>
//         <div className={styles.header}>
//           <h1>Latest Post</h1>
//           <div className={styles.tabs}>
//             {["All", "Blogs", "Tech", "Press", "How to", "Design"].map(
//               (tab) => (
//                 <button
//                   key={tab}
//                   className={`${styles.tab} ${
//                     activeTab === tab ? styles.active : ""
//                   }`}
//                   onClick={() => handleTabClick(tab)}
//                 >
//                   {tab}
//                 </button>
//               )
//             )}
//           </div>
//         </div>
//         <div className={styles.blogs}>
//           {filteredBlogs.slice(0, visibleBlogs).map((blog) => (
//             <div key={blog.id} className={styles.blogCard}>
//               <img
//                 src={blog.image}
//                 alt={blog.header}
//                 className={styles.blogImage}
//               />
//               <div className={styles.blogDetails}>
//                 <p className={styles.blogAuthor}>{blog.author}</p>
//                 <h3 className={styles.blogHeader}>{blog.header}</h3>
//                 <p className={styles.blogDate}>
//                   {blog.date} | {blog.readTime}
//                 </p>
//               </div>
//             </div>
//           ))}
//         </div>
//         <div className={styles.buttons}>
//           {visibleBlogs < filteredBlogs.length ? (
//             <button onClick={handleViewMore} className={styles.viewMore}>
//               View More
//             </button>
//           ) : (
//             visibleBlogs > 6 && (
//               <button onClick={handleViewLess} className={styles.viewLess}>
//                 View Less
//               </button>
//             )
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LatestPost;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import client from "../sanityClient";
import styles from "./LatestPost.module.css";
import { urlFor } from "../sanityClient"; // Import the urlFor function

const LatestPost = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [blogs, setBlogs] = useState([]);
  const [visibleBlogs, setVisibleBlogs] = useState(6);
  const navigate = useNavigate();

  useEffect(() => {
    client
      .fetch(
        `*[_type == "blogLatest"]{
          _id,
          category,
          image,
          author,
          header,
          date,
          readTime,
          content
        }`
      )
      .then((data) => setBlogs(data))
      .catch(console.error);
  }, []);

  const handleTabClick = (category) => {
    setActiveTab(category);
    setVisibleBlogs(6); // Reset to show limited blogs when changing category
  };

  const handleViewMore = () => {
    setVisibleBlogs(blogs.length);
  };

  const handleViewLess = () => {
    setVisibleBlogs(6);
  };

  const handleBlogClick = (blog) => {
    navigate(`/blog/${blog._id}`, { state: { blog } });
  };

  const filteredBlogs =
    activeTab === "All"
      ? blogs
      : blogs.filter((blog) => blog.category === activeTab);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1>Latest Post</h1>
          <div className={styles.tabs}>
            {["All", "Blogs", "Tech", "Press", "How to", "Design"].map(
              (tab) => (
                <button
                  key={tab}
                  className={`${styles.tab} ${
                    activeTab === tab ? styles.active : ""
                  }`}
                  onClick={() => handleTabClick(tab)}
                >
                  {tab}
                </button>
              )
            )}
          </div>
        </div>
        <div className={styles.blogs}>
          {filteredBlogs.slice(0, visibleBlogs).map((blog) => (
            <div
              key={blog._id}
              className={styles.blogCard}
              onClick={() => handleBlogClick(blog)}
            >
              <img
                src={urlFor(blog.image).url()} // Use urlFor to get the image URL
                alt={blog.header}
                className={styles.blogImage}
              />
              <div className={styles.blogDetails}>
                <p className={styles.blogAuthor}>{blog.author}</p>
                <h3 className={styles.blogHeader}>{blog.header}</h3>
                <p className={styles.blogDate}>
                  {new Date(blog.date).toLocaleDateString()} | {blog.readTime}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.buttons}>
          {visibleBlogs < filteredBlogs.length ? (
            <button onClick={handleViewMore} className={styles.viewMore}>
              View More
            </button>
          ) : (
            visibleBlogs > 6 && (
              <button onClick={handleViewLess} className={styles.viewLess}>
                View Less
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default LatestPost;
