// import React, { useState, useEffect } from "react";
// import styles from "./ServiceInfo.module.css";
// import image from "./image1.png";
// import icon2 from "./icon2.png";

// // Dummy data for services
// const services = [
//   {
//     name: "AIR FREIGHT",
//     description:
//       "Our air freight services are designed to meet your business's urgent and time-sensitive shipping needs. We offer a comprehensive range of solutions tailored to ensure that your cargo reaches its destination quickly and safely.",
//     image: image,
//     cards: [
//       {
//         icon: icon2,
//         header: "Global Network:",
//         description:
//           "Leveraging a vast network of airline partnerships, we provide you with extensive global reach. Our strategic alliances enable us to offer competitive rates and optimal routing for your shipments.",
//       },
//       {
//         icon: icon2,
//         header: "Customizable Services:",
//         description:
//           "Leveraging a vast network of airline partnerships, we provide you with extensive global reach. Our strategic alliances enable us to offer competitive rates and optimal routing for your shipments.",
//       },
//       {
//         icon: icon2,
//         header: "Advanced Tracking Technology:",
//         description:
//           "Leveraging a vast network of airline partnerships, we provide you with extensive global reach. Our strategic alliances enable us to offer competitive rates and optimal routing for your shipments.",
//       },
//       {
//         icon: icon2,
//         header: "Expert Handling:",
//         description:
//           "Leveraging a vast network of airline partnerships, we provide you with extensive global reach. Our strategic alliances enable us to offer competitive rates and optimal routing for your shipments.",
//       },
//     ],
//   },
//   {
//     name: "Service 2",
//     description: "Description for Service 2",
//     image: image,
//     cards: [
//       {
//         icon: icon2,
//         header: "Header 1",
//         description: "Card 1 description",
//       },
//       {
//         icon: icon2,
//         header: "Header 2",
//         description: "Card 2 description",
//       },
//       {
//         icon: icon2,
//         header: "Header 1",
//         description: "Card 1 description",
//       },
//       {
//         icon: icon2,
//         header: "Header 2",
//         description: "Card 2 description",
//       },
//     ],
//   },
//   // Add more service objects as needed
// ];

// const ServiceInfo = () => {
//   const [visibleServices, setVisibleServices] = useState([services[0]]);
//   const [loading, setLoading] = useState(false);

//   const handleScroll = () => {
//     const container = document.querySelector(`.${styles.container}`);
//     const lastService = document.querySelector(
//       `.${styles.serviceContainer}:last-child`
//     );
//     const containerBottom = container.scrollTop + container.clientHeight;
//     const lastServiceBottom = lastService.offsetTop + lastService.offsetHeight;

//     if (containerBottom >= lastServiceBottom && !loading) {
//       setLoading(true);
//       setVisibleServices((prev) => {
//         const nextServices = services.slice(prev.length, prev.length + 2); // Load two more services at a time
//         setLoading(false);
//         return [...prev, ...nextServices];
//       });
//     }
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, [loading]);

//   return (
//     <div className={styles.container}>
//       {visibleServices.map((service, index) => (
//         <div key={index} className={styles.serviceContainer}>
//           <div className={styles.serviceHeader}>
//             <h2 className={styles.name}>{service.name}</h2>
//             <p className={styles.description}>{service.description}</p>
//           </div>
//           <img
//             src={service.image}
//             alt={service.name}
//             className={styles.serviceImage}
//           />
//           <div className={styles.cardsContainer}>
//             {service.cards.map((card, cardIndex) => (
//               <div key={cardIndex} className={styles.card}>
//                 <div className={styles.headerContainer}>
//                   <img
//                     src={card.icon}
//                     alt={card.header}
//                     className={styles.cardIcon}
//                   />
//                   <h3 className={styles.cardHeader}>{card.header}</h3>
//                 </div>

//                 <p className={styles.cardDescription}>{card.description}</p>
//               </div>
//             ))}
//           </div>
//           <button className={styles.contactButton}>Contact Us</button>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ServiceInfo;

import React, { useState, useEffect, useRef } from "react";
import styles from "./ServiceInfo.module.css";
import client from "../../sanityClient"; // Adjust the path based on your project structure
import { Link } from "react-router-dom";

const ServiceInfo = () => {
  const [visibleServices, setVisibleServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(2); // Number of items to fetch at a time

  const containerRef = useRef(null);

  const fetchServices = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const query = `*[_type == "serviceInfo"]{name, description, image{asset->{url}}, cards[]{icon{asset->{url}}, header, description}, contactButtonText}`;
      const data = await client.fetch(query);

      if (data.length > 0) {
        const newServices = data.slice(start, start + limit);
        setVisibleServices((prev) => [...prev, ...newServices]);
        setStart((prev) => prev + limit); // Update start for the next batch
        setHasMore(start + limit < data.length); // Update hasMore if there are more services to load
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (loading || !hasMore) return;

    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollHeight - scrollTop <= clientHeight * 1.1) {
        // Trigger fetching when near bottom
        fetchServices();
      }
    }
  };

  useEffect(() => {
    fetchServices();
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [start, limit, hasMore, loading]);

  return (
    <div ref={containerRef} className={styles.container}>
      {visibleServices.length > 0 ? (
        visibleServices.map((service, index) => (
          <div key={index} className={styles.serviceContainer}>
            <div className={styles.serviceHeader}>
              <h2 className={styles.name}>{service.name}</h2>
              <p className={styles.description}>{service.description}</p>
            </div>
            <img
              src={service.image?.asset?.url}
              alt={service.name}
              className={styles.serviceImage}
            />
            <div className={styles.cardsContainer}>
              {service.cards && service.cards.length > 0 ? (
                service.cards.map((card, cardIndex) => (
                  <div key={cardIndex} className={styles.card}>
                    <div className={styles.headerContainer}>
                      <img
                        src={card.icon?.asset?.url}
                        alt={card.header}
                        className={styles.cardIcon}
                      />
                      <h3 className={styles.cardHeader}>{card.header}</h3>
                    </div>
                    <p className={styles.cardDescription}>{card.description}</p>
                  </div>
                ))
              ) : (
                <p>No cards available</p>
              )}
            </div>
            {/* <Link
              className={styles.contactButton}
              to="#contact-us"
              onClick={(e) => handleFormScroll(e, "contact-us")}
            >
              {service.contactButtonText || "Contact Us"}
            </Link> */}
          </div>
        ))
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default ServiceInfo;
