import React, { useState, useEffect } from "react";
import styles from "./MacroService.module.css";
import client from "../../sanityClient"; // Adjust path as necessary

import imagePlaceholder from "./image.png"; // Placeholder in case image is not available

const MacroService = () => {
  const [pageData, setPageData] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const query =
        '*[_type == "macroServicePage"]{badge, description, services[]{..., image{asset->}}}';
      const result = await client.fetch(query);
      if (result.length > 0) {
        setPageData(result[0]);
      }
    };

    fetchData();
  }, []);

  if (!pageData) {
    return <div></div>;
  }

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.badgeContainer}>
        <span className={styles.badge}>{pageData.badge}</span>
      </div>
      <div className={styles.descDiv}>
        <p className={styles.description}>{pageData.description}</p>
      </div>

      <div className={styles.content}>
        {pageData.services.map((item, index) => (
          <div
            key={index}
            className={`${styles.item} ${
              hoveredIndex === index ? styles.expanded : ""
            }`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <div className={styles.descriptionContainer}>
              <span className={styles.itemText}>{item.text}</span>
              <div className={styles.itemDescriptionDiv}>
                <span className={styles.itemDescription}>
                  {item.description}
                </span>
              </div>
            </div>
            {hoveredIndex === index && (
              <div className={styles.expandedContent}>
                <div className={styles.leftColumn}>
                  <p className={styles.subDesc}>{item.subDesc}</p>
                  <button className={styles.keyFeaturesButton}>
                    Key Features
                  </button>
                  <ul className={styles.keyFeatueDiv}>
                    {item?.keyFeatures?.map((feature, i) => (
                      <li key={i} className={styles.listsDiv}>
                        <span className={styles.title}>{feature?.title}:</span>
                        <span className={styles.keyDescription}>
                          {feature?.description}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={styles.rightColumn}>
                  <img
                    src={
                      item.image?.asset
                        ? item.image.asset.url
                        : imagePlaceholder
                    }
                    alt={`${item.text} illustration`}
                    className={styles.image}
                  />
                </div>
              </div>
            )}
            <hr className={styles.separator} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MacroService;
