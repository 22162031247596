import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "normalize.css";
import "./App.css";

import Intro from "./components/content/Intro/Intro";
import Slider from "./components/content/Silder/Slider";
import Navbar from "./components/Navbar/Navbar";
import Transportation from "./components/content/transportation/Transportation";
import About from "./components/content/about/About";
import MacroService from "./components/content/macroservices/MacroServiceHome";
import Work from "./components/content/works/Work";
import ChooseUs from "./components/content/chooseUs/ChooseUs";
import Industries from "./components/content/industries/Industries";
import Dimension from "./components/content/dimension/Dimension";
import Form from "./components/content/form/Form";
import Footer from "./components/content/footer/Footer";
import Maps from "./components/content/map/Maps";
import Services from "./components/content/services/Services";
import ServicePage from "./components/serviceDetail/ServicePage";
import BlogDetailsPage from "./components/blogDetails/BlogDetailsPage";
import BlogPage from "./components/blogPage/BlogPage";
import PrivacyPage from "./components/legal/privacyPolicy/PrivacyPage";
import CookiesPage from "./components/legal/cookiesPolicy/CookiesPage";
// import CookiePolicy from "./components/legal/CookiePolicy";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/why-choose-us" element={<WhyChooseUs />} />
          <Route path="/work" element={<Work />} />
          <Route path="/fleet" element={<Fleet />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/serviceDetail" element={<ServiceDetail />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:id" element={<BlogDetailsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPage />} />
          <Route path="/cookie-policy" element={<CookiesPage />} />
          <Route path="/legals" element={<Legals />} />
        </Routes>
      </div>
    </Router>
  );
}

const Home = () => (
  <>
    <Intro />
    <Slider />
    <Transportation />
    <About />
    <Services />
    <MacroService />
    <Work />
    <ChooseUs />
    <Industries />
    <Dimension />
    <Maps />
    <Form />
    <Footer />
  </>
);

const AboutUs = () => <About />;

const WhyChooseUs = () => <ChooseUs />;

const Fleet = () => <Transportation />;

const ContactUs = () => <Form />;

const ServiceDetail = () => <ServicePage />;

// const Blog = () => <div>Blog Content</div>;

const Legals = () => <div>Legals Content</div>;

export default App;
