// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import styles from "./Navbar.module.css";
// import aboutUsHover from "./aboutUsHover.png"; // Example image for about us
// import chooseUsHover from "./chooseUsHover.jpg"; // Example image for about us
// import workHover from "./workHover.jpg"; // Example image for work
// import fleetHover from "./fleetHover.jpg"; // Example image for fleet
// import contactUsHover from "./contactUsHover.jpg"; // Example image for contact us
// import serviceHoverImage from "./serviceHoverImage.jpg";
// import blogHover from "./blogsHover.jpg";
// import legalHover from "./legalHoverImg.jpg";
// import hamburgerIcon from "./hamburger.png";
// import closeIcon from "./close.png";
// import rightArrow from "./right-arrow.png";
// import logoWeb from "./logo-web.png";

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [hoveredItem, setHoveredItem] = useState(null);
//   const navigate = useNavigate();

//   const handleToggle = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleClose = () => {
//     setIsOpen(false);
//   };

//   const handleScroll = (event, sectionId) => {
//     event.preventDefault();
//     handleClose();
//     const element = document.getElementById(sectionId);
//     if (element) {
//       element.scrollIntoView({ behavior: "smooth" });
//     }
//   };

//   const handleMouseEnter = (item) => {
//     setHoveredItem(item);
//   };

//   const handleMouseLeave = () => {
//     setHoveredItem(null);
//   };

//   const getImageForHoveredItem = (item) => {
//     switch (item) {
//       case "about-us":
//         return aboutUsHover;
//       case "work":
//         return workHover;
//       case "why-choose-us":
//         return chooseUsHover;
//       case "fleet":
//         return fleetHover;
//       case "contact-us":
//         return contactUsHover;
//       case "service":
//         return serviceHoverImage;
//       case "blog":
//         return blogHover;
//       case "legal":
//         return legalHover;
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className={styles.navContainer}>
//       <nav className={styles.navbar}>
//         <div className={styles.logo}>
//           <Link to="/" onClick={handleClose}>
//             <img src={logoWeb} alt="Logo" />
//           </Link>
//         </div>
//         <div className={styles.menu}>
//           <div className={styles.hamburger} onClick={handleToggle}>
//             <img src={isOpen ? closeIcon : hamburgerIcon} alt="Menu" />
//           </div>
//         </div>
//       </nav>
//       {isOpen && (
//         <div className={`${styles.expandedMenu} ${isOpen ? styles.open : ""}`}>
//           <div className={styles.expandedContent}>
//             <div className={styles.left}>
//               <Link to="/" onClick={handleClose}>
//                 <p className={styles.navItemText}>Home</p>
//               </Link>
//               <ul>
//                 <div className={styles.innerNavItemDiv}>
//                   <li
//                     onMouseEnter={() => handleMouseEnter("about-us")}
//                     onMouseLeave={handleMouseLeave}
//                   >
//                     <Link
//                       to="#about-us"
//                       onClick={(e) => handleScroll(e, "about-us")}
//                     >
//                       <p className={styles.navItemSubText}>About Us</p>
//                     </Link>
//                   </li>
//                   <li
//                     onMouseEnter={() => handleMouseEnter("why-choose-us")}
//                     onMouseLeave={handleMouseLeave}
//                   >
//                     <Link
//                       to="#why-choose-us"
//                       onClick={(e) => handleScroll(e, "why-choose-us")}
//                     >
//                       <p className={styles.navItemSubText}>Why Choose Us</p>
//                     </Link>
//                   </li>
//                   <li
//                     onMouseEnter={() => handleMouseEnter("work")}
//                     onMouseLeave={handleMouseLeave}
//                   >
//                     <Link to="#work" onClick={(e) => handleScroll(e, "work")}>
//                       <p className={styles.navItemSubText}>Work</p>
//                     </Link>
//                   </li>
//                   <li
//                     onMouseEnter={() => handleMouseEnter("fleet")}
//                     onMouseLeave={handleMouseLeave}
//                   >
//                     <Link to="#fleet" onClick={(e) => handleScroll(e, "fleet")}>
//                       <p className={styles.navItemSubText}>Fleet</p>
//                     </Link>
//                   </li>
//                   <li
//                     onMouseEnter={() => handleMouseEnter("contact-us")}
//                     onMouseLeave={handleMouseLeave}
//                   >
//                     <Link
//                       to="#contact-us"
//                       onClick={(e) => handleScroll(e, "contact-us")}
//                     >
//                       <p className={styles.navItemSubText}>Contact Us</p>
//                     </Link>
//                   </li>
//                 </div>

//                 <hr />
//                 <li
//                   onMouseEnter={() => handleMouseEnter("service")}
//                   onMouseLeave={handleMouseLeave}
//                 >
//                   <Link to="/serviceDetail" onClick={handleClose}>
//                     <p className={styles.navItemText}>Services</p>
//                   </Link>
//                 </li>
//                 <hr />
//                 <li
//                   onMouseEnter={() => handleMouseEnter("blog")}
//                   onMouseLeave={handleMouseLeave}
//                 >
//                   <Link to="/blog" onClick={handleClose}>
//                     <p className={styles.navItemText}>Blogs</p>
//                   </Link>
//                 </li>
//                 <hr />
//                 <li
//                   onMouseEnter={() => handleMouseEnter("legal")}
//                   onMouseLeave={handleMouseLeave}
//                 >
//                   <Link to="/privacy-policy" onClick={handleClose}>
//                     <p className={styles.navItemText}>Legals</p>
//                   </Link>
//                 </li>
//                 <hr />
//                 <li className={styles.getStartedMobile}>
//                   <Link
//                     to="#contact-us"
//                     className={styles.getStarted}
//                     onClick={(e) => handleScroll(e, "contact-us")}
//                   >
//                     Get Started
//                     <span className={styles.arrow}>
//                       <img src={rightArrow} alt="arrow" />
//                     </span>
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//             <div className={styles.right}>
//               <div className={styles.imagePreview}>
//                 {hoveredItem && (
//                   <img
//                     src={getImageForHoveredItem(hoveredItem)}
//                     alt="Preview"
//                   />
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Navbar;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import client from "../sanityClient";
import imageUrlBuilder from "@sanity/image-url";
import styles from "./Navbar.module.css";

// Function to generate image URLs
const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [navbarData, setNavbarData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    client
      .fetch(
        `*[_type == "navbar"][0]{
          logo,
          hamburgerIcon,
          closeIcon,
          rightArrow,
          menuItems[]{
            title,
            sectionId,
            link,
            hoverImage,
            type
          }
        }`
      )
      .then((data) => setNavbarData(data))
      .catch(console.error);
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleNavigation = (event, item) => {
    event.preventDefault();
    handleClose();
    if (item.type === "scroll") {
      const element = document.getElementById(item.sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else if (item.type === "navigate") {
      navigate(item.link);
    }
  };

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const getImageForHoveredItem = (item) => {
    const menuItem = navbarData.menuItems.find((menu) => menu.title === item);
    return menuItem?.hoverImage ? urlFor(menuItem.hoverImage).url() : null;
  };

  if (!navbarData) {
    return <div></div>;
  }

  // Define a set of titles that should use the `navItemText` style
  const navItemSpecialTitles = new Set(["Services", "Blogs", "Home", "Legals"]);

  return (
    <div className={styles.navContainer}>
      <nav className={styles.navbar}>
        <div className={styles.logo}>
          <Link to="/" onClick={handleClose}>
            <img src={urlFor(navbarData.logo).url()} alt="Logo" />
          </Link>
        </div>
        <div className={styles.menu}>
          <div className={styles.hamburger} onClick={handleToggle}>
            <img
              src={
                isOpen
                  ? urlFor(navbarData.closeIcon).url()
                  : urlFor(navbarData.hamburgerIcon).url()
              }
              alt="Menu"
            />
          </div>
        </div>
      </nav>
      {isOpen && (
        <div className={`${styles.expandedMenu} ${isOpen ? styles.open : ""}`}>
          <div className={styles.expandedContent}>
            <div className={styles.left}>
              <Link to="/" onClick={handleClose}>
                <p className={styles.navItemText}>Home</p>
              </Link>
              <ul>
                <div className={styles.innerNavItemDiv}>
                  {navbarData.menuItems.map((item) => (
                    <li
                      key={item.title}
                      onMouseEnter={() => handleMouseEnter(item.title)}
                      onMouseLeave={handleMouseLeave}
                      className={
                        navItemSpecialTitles.has(item.title)
                          ? styles.topLevelItem
                          : styles.subItem
                      }
                    >
                      <Link
                        to={item.type === "navigate" ? item.link : "#"}
                        onClick={(e) => handleNavigation(e, item)}
                      >
                        <p
                          className={
                            navItemSpecialTitles.has(item.title)
                              ? styles.navItemText
                              : styles.navItemSubText
                          }
                        >
                          {item.title}
                        </p>
                        <hr className={styles.hrLine} />
                      </Link>
                      {item.subItems && (
                        <ul className={styles.subMenu}>
                          {item.subItems.map((subItem) => (
                            <li key={subItem.title}>
                              <Link
                                to={
                                  subItem.type === "navigate"
                                    ? subItem.link
                                    : "#"
                                }
                                onClick={(e) => handleNavigation(e, subItem)}
                              >
                                <p className={styles.navItemSubText}>
                                  {subItem.title}
                                </p>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </div>
                {/* <hr /> */}
                <li className={styles.getStartedMobile}>
                  <Link
                    to="#contact-us"
                    className={styles.getStarted}
                    onClick={(e) =>
                      handleNavigation(e, {
                        type: "scroll",
                        sectionId: "contact-us",
                      })
                    }
                  >
                    Get Started
                    <span className={styles.arrow}>
                      <img
                        src={urlFor(navbarData.rightArrow).url()}
                        alt="arrow"
                      />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.right}>
              <div className={styles.imagePreview}>
                {hoveredItem && (
                  <img
                    src={getImageForHoveredItem(hoveredItem)}
                    alt="Preview"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
