// import React, { useEffect, useState } from "react";
// import styles from "./Intro.module.css";

// const Intro = () => {
//   const texts = [
//     {
//       big: "Efficient Logistics Solutions for Global Cargo Transportation",
//       small:
//         "Offering reliable, cost-effective shipping services for containers to destinations worldwide, ensuring your cargo arrives safely and efficiently on time.",
//       layout: "left",
//       id: "firstText",
//     },
//     {
//       big: "Seamless Container Transport for Your Business Needs!",
//       small:
//         "Providing expert handling and timely delivery of shipments, we ensure your business operations run smoothly and efficiently without any delays and discrepancies.",
//       layout: "center",
//       id: "secondText",
//     },
//     {
//       big: "Your Freight, Our Priority: Secure and Efficient Transport!",
//       small:
//         "Committed to delivering secure, efficient freight transportation solutions tailored to meet the unique needs of your business.",
//       layout: "right",
//       id: "thirdText",
//     },
//   ];

//   const [currentTextIndex, setCurrentTextIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentTextIndex((prev) => (prev + 1) % texts.length);
//     }, 4000); // Change text every 4 seconds

//     return () => clearInterval(interval);
//   }, [texts.length]);

//   const { big, small, layout, id } = texts[currentTextIndex];

//   return (
//     <div className={styles.container}>
//       <div className={`${styles.textContainer} ${styles[layout]}`}>
//         <div
//           className={`${styles.bigText} ${
//             id === "secondText" ? styles.secondTextBig : ""
//           }`}
//         >
//           {big}
//         </div>
//         <div
//           className={`${styles.smallText} ${
//             id === "firstText" ? styles.firstTextSmall : ""
//           } ${id === "thirdText" ? styles.thirdTextSmall : ""} ${
//             id === "secondText" ? styles.secondTextMargin : ""
//           }`}
//         >
//           {small}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Intro;

import React, { useEffect, useState } from "react";
import styles from "./Intro.module.css";
import client from "../../sanityClient"; // Adjust the path according to your project structure

const Intro = () => {
  const [texts, setTexts] = useState([]);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    // Fetch the data from Sanity
    client
      .fetch(
        `*[_type == "introText"] | order(_createdAt asc) {
          big,
          small,
          layout,
          id
        }`
      )
      .then((data) => {
        setTexts(data);
      })
      .catch((error) => {
        console.error("Error fetching intro texts:", error);
      });
  }, []);

  useEffect(() => {
    if (texts.length > 0) {
      const interval = setInterval(() => {
        // setCurrentTextIndex((prev) => (prev + 1) % texts.length);
      }, 4000); // Change text every 4 seconds

      return () => clearInterval(interval);
    }
  }, [texts]);

  if (texts.length === 0) return <div></div>;

  const { big, small, layout, id } = texts[currentTextIndex];

  return (
    <div className={styles.container}>
      <div className={`${styles.textContainer} ${styles[layout]}`}>
        <div
          className={`${styles.bigText} ${
            id === "secondText" ? styles.secondTextBig : ""
          }`}
        >
          {big}
        </div>
        <div
          className={`${styles.smallText} ${
            id === "firstText" ? styles.firstTextSmall : ""
          } ${id === "thirdText" ? styles.thirdTextSmall : ""} ${
            id === "secondText" ? styles.secondTextMargin : ""
          }`}
        >
          {small}
        </div>
      </div>
    </div>
  );
};
export default Intro;
