// import React from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./Blogs.module.css";
// import blogImg from "./blogPic.png";
// import rightArrow from "./rightArrow.png";

// const Blogs = () => {
//   const navigate = useNavigate();

//   const blogs = [
//     {
//       id: 1,
//       title:
//         "Mastering ChatGPT Blog Creation: Dos and Don'ts for SaaS Marketing Managers",
//       image: blogImg,
//       shortText:
//         "Short text from the article. It provides a brief summary or teaser for the blog post.",
//       datePosted: "2024-07-25",
//       readingTime: "5 min",
//       content: [
//         {
//           subTitle: "Introduction to Supply Chain Optimization",
//           paragraph: `Hello there! As a marketing manager in the SaaS industry, you might be looking for innovative ways to engage your audience. I bet generative AI has crossed your mind as an option for creating content. Well, let me share from my firsthand experience.

// Google encourages high-quality blogs regardless of whether they're written by humans or created using artificial intelligence like ChatGPT. Here's what matters: producing original material with expertise and trustworthiness based on Google E-E-A-T principles.

// This means focusing more on people-first writing rather than primarily employing AI tools to manipulate search rankings. There comes a time when many experienced professionals want to communicate their insights but get stuck due to limited writing skills – that’s where Generative AI can step in.

// So, together, we’re going explore how this technology could help us deliver valuable content without sounding robotic or defaulting into mere regurgitations of existing materials (spoiler alert – common pitfalls!). Hang tight - it’ll be a fun learning journey!`,
//         },
//         {
//           subTitle: "Steering Clear of Common AI Writing Pitfalls",
//           paragraph: `Jumping headfirst into using AI, like ChatGPT, without a content strategy can lead to some unfortunate results. One common pitfall I've seen is people opting for quantity over quality - they churn out blogs, but each one feels robotic and soulless, reading just like countless others on the internet.

// Another fault line lies in creating reproductions rather than delivering unique perspectives that offer value to readers; it often happens if you let an AI tool write your full blog unrestrained! Trust me on this – Ask any experienced marketer or writer about their takeaways from using generative AI tools. They'll all agree that adding a human touch and following specific guidelines are key when implementing these tech pieces.

// Remember, our goal here isn’t merely satisfying search engines but, more importantly, knowledge-hungry humans seeking reliable information online. So keep your audience's needs at heart while leveraging technology’s assistance!`,
//         },
//         {
//           subTitle: "Steering Clear of Common AI Writing Pitfalls",
//           paragraph:
//             "Discussion of common challenges in supply chain management and practical solutions to overcome these obstacles, ensuring a smooth and efficient process.",
//         },
//       ],
//     },

//     {
//       id: 2,
//       title: "2Optimizing Your Supply Chain for Maximum Efficiency",
//       image: blogImg,
//       shortText:
//         "Short text from the article. It provides a brief summary or teaser for the blog post.",
//       datePosted: "2024-07-25",
//       readingTime: "5 min",
//       content: [
//         {
//           subTitle: "Introduction to Supply Chain Optimization",
//           paragraph:
//             "An introduction to the basics of supply chain optimization, explaining its importance and the general strategies used.",
//         },
//         {
//           subTitle: "Key Strategies for Efficiency",
//           paragraph:
//             "This section delves into specific strategies to enhance supply chain efficiency, including lean management, just-in-time inventory, and technology integration.",
//         },
//         {
//           subTitle: "Challenges and Solutions",
//           paragraph:
//             "Discussion of common challenges in supply chain management and practical solutions to overcome these obstacles, ensuring a smooth and efficient process.",
//         },
//       ],
//     },
//     {
//       id: 3,
//       title: "3Optimizing Your Supply Chain for Maximum Efficiency",
//       image: blogImg,
//       shortText:
//         "Short text from the article. It provides a brief summary or teaser for the blog post.",
//       datePosted: "2024-07-25",
//       readingTime: "5 min",
//       content: [
//         {
//           subTitle: "Introduction to Supply Chain Optimization",
//           paragraph:
//             "An introduction to the basics of supply chain optimization, explaining its importance and the general strategies used.",
//         },
//         {
//           subTitle: "Key Strategies for Efficiency",
//           paragraph:
//             "This section delves into specific strategies to enhance supply chain efficiency, including lean management, just-in-time inventory, and technology integration.",
//         },
//         {
//           subTitle: "Challenges and Solutions",
//           paragraph:
//             "Discussion of common challenges in supply chain management and practical solutions to overcome these obstacles, ensuring a smooth and efficient process.",
//         },
//       ],
//     },
//     // ... more blog data
//   ];

//   const handleReadMore = (blog) => {
//     navigate(`/blog/${blog.id}`, { state: { blog } });
//   };

//   return (
//     <div className={styles.blogContainer}>
//       <div className={styles.blogWrapper}>
//         <div className={styles.headerRow}>
//           <div className={styles.headerCol}>
//             <h1 className={styles.headerText}>
//               Logistics Insights & Shipping Solutions That Enhance Efficiency &
//               Drive Growth
//             </h1>
//           </div>
//           <div className={styles.descCol}>
//             <p className={styles.paraText}>
//               From innovative shipping solutions and supply chain strategies to
//               best practices and emerging trends, our blog is your go-to
//               resource for staying informed and ahead in the world of cargo and
//               shipments.
//             </p>
//             <button className={styles.seeMoreButton}>See More</button>
//           </div>
//         </div>

//         <div className={styles.cardsRow}>
//           {blogs.map((blog) => (
//             <div key={blog.id} className={styles.card}>
//               <div className={styles.cardHeader}>
//                 <div className={styles.circleDot}></div>
//                 <span className={styles.time}>{blog.readingTime}</span>
//               </div>
//               <h2 className={styles.blogHeading}>{blog.title}</h2>
//               <img className={styles.blogImage} src={blog.image} alt="" />
//               <div className={styles.cardFooter}>
//                 <p className={styles.shortText}>{blog.shortText}</p>
//                 <button
//                   className={styles.readMoreButton}
//                   onClick={() => handleReadMore(blog)}
//                 >
//                   <img
//                     className={styles.arrowImg}
//                     src={rightArrow}
//                     alt="right arrow"
//                   />
//                 </button>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Blogs;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Blogs.module.css";
import rightArrow from "./rightArrow.png";
import client, { urlFor } from "../../sanityClient";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [siteSettings, setSiteSettings] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const blogQuery = '*[_type == "blog"]';
        const settingsQuery = '*[_type == "siteSettings"]';
        const blogResult = await client.fetch(blogQuery);
        const settingsResult = await client.fetch(settingsQuery);
        setBlogs(blogResult);
        setSiteSettings(settingsResult[0]);
      } catch (error) {
        console.error("Error fetching content:", error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    };

    fetchContent();
  }, []);

  const handleReadMore = (blog) => {
    navigate(`/blog/${blog._id}`, { state: { blog } });
  };

  const handleSeeMore = () => {
    navigate("/blog"); // Adjust this to the route for the "See More" component
  };

  if (isLoading) {
    return <div></div>; // Render a loading state while data is being fetched
  }

  return (
    <div className={styles.blogContainer}>
      <div className={styles.blogWrapper}>
        <div className={styles.headerRow}>
          <div className={styles.headerCol}>
            <h1 className={styles.headerText}>{siteSettings.headerText}</h1>
          </div>
          <div className={styles.descCol}>
            <p className={styles.paraText}>{siteSettings.paragraphText}</p>
            <button className={styles.seeMoreButton} onClick={handleSeeMore}>
              {siteSettings.seeMoreButtonText}
            </button>
          </div>
        </div>

        <div className={styles.cardsRow}>
          {blogs.map((blog) => (
            <div key={blog._id} className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.circleDot}></div>
                <span className={styles.time}>{blog.readTime}</span>
              </div>
              <h2 className={styles.blogHeading}>{blog.header}</h2>
              <img
                className={styles.blogImage}
                src={urlFor(blog.image).url()} // Use the urlFor function
                alt=""
              />
              <div className={styles.cardFooter}>
                <p className={styles.shortText}>{blog.shortText}</p>
                <button
                  className={styles.readMoreButton}
                  onClick={() => handleReadMore(blog)}
                >
                  <img
                    className={styles.arrowImg}
                    src={rightArrow}
                    alt="right arrow"
                  />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
